import React from 'react';
import { Link } from 'gatsby';

import { Layout } from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout siteMetadata={{ title: 'Subterranean Flower' }}>
    <SEO title="Subterranean Flower" />
    ぶろぐ → <Link to="/blog/">Subterranean Flower Blog</Link>
  </Layout>
);

export default IndexPage;
